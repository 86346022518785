<!--
* @description:
* @fileName shopManage.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.shopName"
              clearable
              placeholder="请输入名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="排序" prop="sort" show-overflow-tooltip />
      <el-table-column label="店铺名称" prop="shopName" show-overflow-tooltip />
      <el-table-column
        label="联系电话1"
        prop="shopContactNumber"
        show-overflow-tooltip
      />
      <el-table-column
        label="联系地址"
        prop="shopContactNumber"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.shopProvince }} - {{ row.shopCity }} - {{ row.shopArea }} -
          {{ row.shopAddress }}
        </template>
      </el-table-column>
      <el-table-column
        label="营业时间"
        prop="shopBusinessTime"
        show-overflow-tooltip
      />

      <el-table-column label="状态" prop="status" show-overflow-tooltip>
        <template #default="{ row }">
          <el-switch
            v-model="row.status"
            size="large"
            active-text="上线"
            inactive-text="下线"
            @change="shopStatusChange(row.id, row.status)"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="320">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
          <el-button type="success" @click="handleLinkByBindPhone(row)">
            <vab-icon icon="edit-line" />
            核销管理
          </el-button>
          <!-- <el-button type="danger" @click="handleLinkByBindPhoneList(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除核销
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="editRef" @fetch-data="fetchData" />

    <el-dialog v-model="dialogVisible" title="链接地址" width="30%">
      <div v-if="seeLink">
        <div>
          <div>二维码图片</div>
          <el-image :src="qrCodeUrl"></el-image>
        </div>

        <div>
          <div>链接地址</div>
          <div>{{ linkUrl }}</div>
        </div>
      </div>

      <div v-else>
        <el-table border :data="linkManagerList">
          <el-table-column label="用户名称" prop="name" show-overflow-tooltip />
          <el-table-column
            label="联系电话"
            prop="phone"
            show-overflow-tooltip
          />
          <el-table-column label="操作" show-overflow-tooltip width="120">
            <template #default="{ row }">
              <el-button type="danger" @click="handleDeleteLinkManager(row)">
                <vab-icon icon="delete-bin-6-line" />
                删除
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
            <el-empty class="vab-data-empty" description="暂无数据" />
          </template>
        </el-table>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import {
    getShopList,
    deleteShop,
    statusShop,
    linkByBindPhone,
    linkByBindPhoneList,
    deletelink,
  } from '@/api/shop'
  export default defineComponent({
    name: 'shopManage',
    components: {
      Edit: defineAsyncComponent(() => import('./components/shopEdit')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: { shopName: '', pageNumber: 1, pageSize: 10 },
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
        dialogVisible: false,
        qrCodeUrl: '',
        linkUrl: '',
        linkManagerList: [],
        seeLink: true,
        editShopId: '',
      })

      const handleEdit = (row) => {
        if (row.id) {
          state.editRef.showEdit(row)
        } else {
          state.editRef.showEdit()
        }
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await deleteShop(row.id)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getShopList(state.queryForm)
        state.list = data.list
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNumber = val
        fetchData()
      }

      const shopStatusChange = async (id, status) => {
        let statusKey = status ? 'ACTIVATION' : 'OFFLINE'
        await statusShop(id, statusKey)
        fetchData()
      }

      const handleLinkByBindPhone = async (row) => {
        // console.log(row)
        // let { data } = await linkByBindPhone(row.id)
        // console.log(data)
        // state.qrCodeUrl = data.qrCodeUrl
        // state.linkUrl = data.url
        // state.dialogVisible = true
        // state.seeLink = true
        proxy.$router.push({ path: `/shop/shopCode/${row.id}` })
      }

      const handleLinkByBindPhoneList = async (row) => {
        queryLinkList(row.id)
        state.editShopId = row.id
        state.dialogVisible = true
        state.seeLink = false
      }

      const queryLinkList = async (id) => {
        let { data } = await linkByBindPhoneList(id)
        state.linkManagerList = data
      }

      const handleDeleteLinkManager = async (row) => {
        let { data } = await deletelink(row.id)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        queryLinkList(state.editShopId)
      }

      fetchData()

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        shopStatusChange,
        Delete,
        Search,
        Plus,
        handleLinkByBindPhone,
        handleLinkByBindPhoneList,
        handleDeleteLinkManager,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
